import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import LoginForm from '@components/forms/login-form'
import authService from '@auth/auth-service'
import Cookies from 'cookies'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { USER_ROLES } from '@utils/constants'

const axiosInstance = axios.create()

export default function LoginPage() {
  const [loginError, setLoginError] = useState('')
  const [loading, setLoading] = useState('')
  const router = useRouter()

  const onSubmit = async (credentials) => {
    try {
      setLoading(true)
      const { organisationId } = await authService.loginAs(
        'manager',
        credentials
      )
      const redirectTo = organisationId ? `/${organisationId}/clients` : '/'
      const returnUrl = router.query.returnUrl || redirectTo
      document.cookie = `lastRedirectTo=${router.asPath}; path=/`
      router.push(returnUrl)
    } catch (err) {
      console.error(err)
      setLoginError({ message: err.error })
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      <Head>
        <title>Log In - Spaze</title>
      </Head>
      <LoginForm
        onSubmit={onSubmit}
        forgotHref={'/login/forgot-password'}
        error={loginError}
        loading={loading}
        // description={
        //   'The web application helping charities and community groups measure, increase & communicate their impact.'
        // }
      />
    </React.Fragment>
  )
}

export const getServerSideProps = async (ctx) => {
  const { req, res, query } = ctx
  const cookies = new Cookies(req, res)
  const token = cookies.get('token')
  const decodedToken = token && jwtDecode(token)
  const isExpired = decodedToken ? Date.now() >= decodedToken.exp * 1000 : false

  if (isExpired) {
    cookies.set('token', null, {
      domain: process.env.APP_DOMAIN,
      expired: new Date(0),
    })
  }

  if (decodedToken && !isExpired) {
    if (decodedToken.role === USER_ROLES.MANAGER) {
      try {
        const { data: user } = await axiosInstance.get(
          `${process.env.NEXT_PUBLIC_API_MANAGERS}/${decodedToken.id}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )

        const targetUrl =
          user.organisations.length > 1
            ? '/'
            : `/${user.organisations[0]}/clients`
        return {
          redirect: {
            permanent: false,
            destination: targetUrl,
          },
        }
      } catch (err) {
        return { props: {} }
      }
    }

    if (decodedToken.role === USER_ROLES.ADMIN) {
      return {
        redirect: {
          permanent: false,
          destination: '/',
        },
      }
    }
  }

  return { props: {} }
}
